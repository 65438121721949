<template>
  <div class="SchoolDetail">
    <div class="School-top pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="5">
            <div class="imgbox xiaohui">
              <img :src="School_detail.school_badge"
                   alt="">
            </div>
          </el-col>
          <el-col :span="11">
            <h3>{{School_detail.en_name}}</h3>
            <p> <i class="iconfont">&#xe62e;</i><b>QS Ranking: </b> {{School_detail.rank == 9999 ? '-' : School_detail.rank}}</p>
            <p> <i class="iconfont">&#xe62e;</i><b>MOE Ranking: </b> {{School_detail.moerank == 9999 ? '-' : School_detail.moerank}}</p>
            <p> <i class="iconfont">&#xe69e;</i><b>City: </b>{{School_detail.province_name}} {{School_detail.city_name}}</p>
            <p> <i class="iconfont">&#xe655;</i><b>Major: </b> <span v-for="(item,index) in School_detail.discipline"
                    :key="index">{{item}}</span></p>
          </el-col>
          <el-col :span="8">
                 <div class="imgbox huanjing">
              <img :src="School_detail.thumb"
                   alt="">
              <div class="icos"
                   @click="showVideoFlag = true"
                   v-if="(School_detail.video != null) && (School_detail.video.length != 0) ">
                <img src="@/assets/vid.png"
                     alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="tit">
              About
            </div>
            <div class="scde"
                 v-html="School_detail.school_Introduction">
            </div>
            <div class="tit">
              Major
            </div>
            <div class="zhuanye">
              <div class="_nav">
                <el-row :gutter="24">
                  <el-col :span="8"
                          class="lis"
                          :class="this.showBlo == 1? 'on':''"
                          @click="this.showBlo = 1">
                    Undergraduate
                  </el-col>
                  <el-col :span="8"
                          class="lis"
                          :class="this.showBlo == 2? 'on':''"
                          @click="this.showBlo = 2">
                    Master's degree
                  </el-col>
                  <el-col :span="8"
                          class="lis"
                          :class="this.showBlo == 3? 'on':''"
                          @click="this.showBlo = 3">
                    Doctoral candidate
                  </el-col>
                </el-row>
              </div>
              <div class="houtaiBlo"
                   v-if="this.showBlo === 1">
                <el-table :data="School_detail.undergraduate"
                          border
                          style="width: 100%"
                          :row-class-name="tableRowClassName">
                  <el-table-column prop="program"
                                   label="PROGRAM"> </el-table-column>

                  <el-table-column prop="duration"
                                   label="DURATION"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="instruction_language"
                                   label="INSTRUCTION LANGUAGE"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="tuiton_Fee"
                                   label="TUITION FEE(RMB)"
                                   width="200"
                                   align="center"> </el-table-column>
                </el-table>
              </div>
              <div class="houtaiBlo"
                   v-else-if="this.showBlo === 2">
                <el-table :data="School_detail.masters_degree"
                          border
                          style="width: 100%"
                          :row-class-name="tableRowClassName">
                  <el-table-column prop="program"
                                   label="PROGRAM"> </el-table-column>

                  <el-table-column prop="duration"
                                   label="DURATION"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="instruction_language"
                                   label="INSTRUCTION LANGUAGE"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="tuiton_Fee"
                                   label="TUITION FEE(RMB)"
                                   width="200"
                                   align="center"> </el-table-column>
                </el-table>
              </div>
              <div class="houtaiBlo"
                   v-else-if="this.showBlo === 3">
                <el-table :data="School_detail.doctoral"
                          border
                          style="width: 100%"
                          :row-class-name="tableRowClassName">
                  <el-table-column prop="program"
                                   label="PROGRAM"> </el-table-column>

                  <el-table-column prop="duration"
                                   label="DURATION"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="instruction_language"
                                   label="INSTRUCTION LANGUAGE"
                                   width="200"
                                   align="center"> </el-table-column>
                  <el-table-column prop="tuiton_Fee"
                                   label="TUITION FEE(RMB)"
                                   width="200"
                                   align="center"> </el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="tit">
          Scholarship
        </div>
        <div class="jiangxuejin"
             v-html="School_detail.scholarship">
        </div>
        <div class="tit">
          Campus Environment
        </div>
        <div class="huanjing">
          <swiper :autoplay="swiper_options.autoplay"
                  :loop="swiper_options.loop"
                  :speed="swiper_options.speed"
                  :pagination="swiper_options.pagination"
                  :navigation="swiper_options.navigation"
                  :spaceBetween="swiper_options.spaceBetween"
                  :centeredSlides="swiper_options.centeredSlides"
                  :slidesPerView="swiper_options.slidesPerView"
                  class="swiper"
                  effect="coverflow">
            <swiper-slide class="hezuoLis"
                          v-for="(item,index) in School_detail.school_environment"
                          :key="index">
              <div class="imgbox">
                <img :src="item"
                     alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

        <el-dialog v-model="showVideoFlag"
               width="600px"
               custom-class="videoBlo"
               destroy-on-close>
      <div class="applyVideo">
        <div v-if="showVideoFlag">
          <div class="videoBox">
            <vue3-video-player :title="School_detail.name"
                               :cover="School_detail.thumb"
                               :autoplay="true"
                               :src="School_detail.video"></vue3-video-player>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { reactive, markRaw } from "vue";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
require("swiper/swiper.min.css");
require("swiper/components/pagination/pagination.scss");
require("swiper/components/navigation/navigation.scss");
SwiperCore.use([Autoplay, Pagination, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data () {
    return {
      showBlo: 1,
      tableData: [
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },
        {
          program: 'ABC',
          degree: 'Doctoral',
          duration: '4.0',
          language: 'Chinese',
          tuitionFee: '34800'
        },

      ],
      School_detail: {},
      showVideoFlag: false,

    }
  },
  setup () {
    let swiper_options = reactive({
      autoplay: {
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 4000, //4秒切换一次
      },
      speed: 500, //切换过渡速度
      loop: true,
      slidesPerView: 3, //解决最后一张切换到第一张中间的空白
      // centeredSlides: true, //设置slide居中
      spaceBetween: 40,

      pagination: {
        clickable: true,
      },
    });
    // 将swiper_options返回给模板中的swiper组件使用
    return { swiper_options };
  },
  beforeMount () {
    axiosApi(
      'university/' + this.$route.params.id, {
    }, 'get'
    ).then((res) => {
      const obj = markRaw(res.data)
      this.School_detail = obj
      console.log(this.School_detail)
    })
  },
  methods: {
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'warning-row'
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.tit {
  border-left: 8px solid #e37f40;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1;
}

.School-top {
  background-color: #174166;
  color: #fff;
  .xiaohui {
    height: 100%;
    justify-content: flex-start;
    img {
      border-radius: 50%;
      width: 180px;
    }
  }
  h3 {
    margin-top: 30px;
  }
  p {
    margin-top: 10px;
    i {
      margin-right: 5px;
    }
    b {
      font-weight: normal;
    }
    span {
      display: inline-block;
      line-height: 1;
      padding: 0 5px;
      border-right: 1px solid #dedede;
      &:last-child {
        border: none;
      }
    }
  }
  .huanjing {
    height: 216px;
    border: 1px solid #fff;
    position: relative;
    .icos {
      width: 80px;
      height: 80px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.9;
      img {
        transform: translateX(3px);
      }
    }
    .icos:hover {
      opacity: 1;
    }
  }
}
._nav {
  overflow: hidden;
  .lis {
    text-align: center;
    height: 80px;
    line-height: 80px;
    background-color: #e6e9ed;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    &.on {
      background-color: #fff;
      color: #e37f40;
    }
    // &.on::before {
    //   content: "";
    //   position: absolute;
    //   top: 32px;
    //   height: 15px;
    //   width: 15px;
    //   left: 50%;
    //   transform: translateX(-50%) rotate(45deg);
    //   background-color: inherit;
    // }
  }
}

.cont {
  background-color: #f8f8f8;
  .scde {
    padding: 20px;
    background-color: #fff;
    font-size: 14px;
    text-indent: 2em;
    line-height: 1.7;
    border: 1px solid #dedede;
    margin-bottom: 40px;
    p {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .zhuanye {
    border: 1px solid #dedede;
    margin-bottom: 40px;
  }
  .houtaiBlo {
    background-color: #fff;
    padding: 30px 20px;
  }
  .huanjing {
    padding: 30px 20px;
    border: 1px solid #dedede;
    background-color: #fff;
    margin-bottom: 40px;
  }
  .jiangxuejin {
    padding: 30px 20px;
    border: 1px solid #dedede;
    background-color: #fff;
    margin-bottom: 40px;
  }
}

.el-table ::v-deep {
  .warning-row {
    --el-table-tr-background-color: #fafafa;
  }
  th.el-table__cell {
    background-color: #fafafa;
    .cell {
      color: #333;
      word-wrap: break-word;
      word-break: normal;
    }
  }
}

.hezuoLis {
  padding-bottom: 40px;
}

.swiper-container ::v-deep {
  .swiper-pagination-bullet-active {
    background-color: #e37f40;
  }
}

.videoBox {
  width: 100%;
  height: 100%;
  .vcp-container ::v-deep {
    .vcp-dashboard {
      width: 100% !important;
      margin-left: 0;
    }
    .play-pause-layer .btn-control::after {
      content: "";
      width: 50px;
      height: 50px;
      background: url(~@/assets/vid.png) 18px center no-repeat;
      background-size: 35%;
    }
    .play-pause-layer .btn-control .btn-play {
      display: none;
    }
    .volume-control .btn-control-panel .volume-info {
      left: -10px;
    }
    .btn-control .tips {
      bottom: 50px;
    }
    .volume-control .btn-control-panel {
      padding: 10px 0;
      height: 100px;
      width: 20px;
    }
    .volume-control .btn-control-panel .progress {
      height: 80px;
      margin-top: -40px;
    }
    .volume-control .btn-control-panel .progress .volume-current,
    .vcp-progress-played {
      background-color: #004168;
    }
    .vcp-switch:after {
      background-color: #004168;
    }
  }
}
</style>


<style lang="scss">
.videoBlo {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    height: 40px;
    .el-dialog__headerbtn {
      right: 0;
    }
    i {
      color: #fff;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    padding: 0px !important;
  }
}
</style>